* {
  box-sizing: border-box;
}

/* Don't know what we want for button styles etc. 
Just messing around with the !importants for now... */
.btn-primary {
  background-color: #033e80 !important;
}
.btn-primary:hover {
  background-color: #0e44c3 !important;
}
nav .active .btn-primary {
  background-color: #0e44c3 !important;
}

.btn-outline-primary {
  color: #033e80 !important;
  border-color: #033e80 !important;
}
.btn-outline-primary:hover {
  color: #0e44c3 !important;
  border-color: #0e44c3 !important;
  background-color: #edf3ff !important;
}
nav .active .btn-outline-primary {
  color: #0e44c3 !important;
  border-color: #0e44c3 !important;
  background-color: #edf3ff !important;
}

.navbar {
  background-color: #ffffff;
}

.nav-link {
  margin: 0 10px;
  color: #033e80 !important;
  font-weight: bold !important;
}

.nav-link.active {
  /* font-weight: bold !important; */
  text-decoration: underline;
}

.nav-item > .dropdown-menu > .dropdown-item.active {
  background-color: #033e80;
}

.logo {
  width: 100%;
  max-width: 95px;
  height: auto;
  max-height: 88px;
}

@media only screen and (max-width: 860px) {
  .nav-link {
    margin: 0 5px;
  }
  .logo {
    max-width: 85px;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler {
  background-color: #033e80 !important;
}
.navbar-toggler:hover {
  background-color: #0030a4 !important;
}

/* .nav:after {
    background: #ffffff;
    content: "";
    display: table;
    clear: both;    
} */

.column {
  float: left;
  padding: 10px;
  box-sizing: border-box;
}

.column:after {
  content: "";
  display: table;
  clear: both;
}

.feature {
  float: left;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 17x;
  line-height: 20px;
  /* identical to box height, or 118% */

  letter-spacing: 1.275px;

  color: #000000;
}

.blue {
  background-color: #033e80;
  color: #ffffff;
}

.left {
  width: 40%;
}

.right {
  width: 60%;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
