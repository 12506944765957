.title-img {
  width: 95%;
  max-width: 400px;
  height: auto;
  margin: 0 auto 8px;
  /* max-height: 186px; */
}

.home {
  width: 100%;
  max-width: 674px;
  height: auto;
  max-height: 359px;
}

.teacher {
  padding: 20px;
  width: 100%;
  max-width: 674px;
  height: auto;
  max-height: 509px;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  padding: 1em;
}

.contentMargin {
  padding-left: 80px;
  padding-right: 80px;
}

.subTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  /* or 93% */

  text-align: center;
  color: #033e80;
}

.numberCircle {
  margin: 0 auto;
  border-radius: 50%;
  width: 78px;
  height: 72px;
  left: 330px;
  top: 617px;

  background: #033e80;
  border: 2px solid #033e80;
  color: #f3cc57;
  text-align: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 68px;
}

.font24 {
  font-size: 24px;
  line-height: 32px;
}

.contentMarginHeight {
  padding-top: 10px;
  padding-bottom: 30px;
}
