.App {
  text-align: center;
}

.parents-list-card,
.teachers-list-card {
  cursor: pointer;
}

.parents-list-card,
.parent-show-card,
.teachers-list-card,
.teacher-show-card {
  max-width: 800px;
  margin: 20px auto;
  padding: 16px;
  background-color: #f6f9ff;
  text-align: center;
  flex-direction: row;
}

.parent-show-card,
.teacher-show-card {
  max-width: 900px;
}

.parents-list-card-img-div,
.parent-show-card-img-div,
.teachers-list-card-img-div,
.teacher-show-card-img-div {
  width: 40%;
  margin-right: 20px;
}
.parents-list-card-img,
.parent-show-card-img,
.teachers-list-card-img,
.teacher-show-card-img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.parent-show-card-img,
.teacher-show-card-img {
  max-height: 250px;
}

.parents-list-card-body,
.parent-show-card-body,
.teachers-list-card-body,
.teacher-show-card-body {
  text-align: left;
  max-width: 55%;
}

.parent-profile-card-img,
.teacher-profile-card-img {
  max-width: 80%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  margin-right: 20px;
}

.class-teacher-img {
  max-width: 80%;
  max-height: 150px;
  margin: 5px;
}

.title-img {
  width: 95%;
  max-width: 400px;
  height: auto;
  margin: 0 auto 4px;
}

.sign-in-card {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f6f9ff;
}

.sign-up-card,
.update-profile-card {
  max-width: 700px;
  margin: 20px auto;
  padding: 24px;
  background-color: #f6f9ff;
}

.home-map-link {
  cursor: pointer;
}

.home-map-card {
  max-width: 95%;
  margin: 20px auto;
  padding: 20px 10px;
  background-color: #f6f9ff;
}

.home-map-card-inset, 
.home-inset-card {
  max-width: 95%;
  margin: 20px auto;
  padding: 16px;
  background-color: #fafdff;
  text-align: center;
}